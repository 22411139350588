<template>
  <!--  Krungsri Biz  -->
  <div>
    <div class="row">
      <div class="col-sm-4 col-lg-3">
        <h5>
          Krungsri Biz
          <AutoBankingStatus :status="!!bayAccount" />
          <br>
          <small class="text-muted">(รองรับฝากเท่านั้น)</small>
        </h5>
      </div>

      <div class="col-sm-8 col-lg-9 pt-2">
        <b-form-checkbox
            v-if="bayAccount"
            id="bayBizStatus"
            v-model="bayAccount.isEnabled"
            name="bayBizStatus"
            switch
        >
          เปิดใช้งาน
        </b-form-checkbox>
        <AutoBankingLinkButton
            :status="!!bayAccount"
            @click="$bvModal.show('bay-biz-modal')"
        />
        <BayBizModal :agent-bank-account-id="bankAccountForm.id" :bay-account="bayAccount" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  name: 'AutoBayForm',
  components: {
    BayBizModal: () => import('./bay-biz-modal'),
    AutoBankingStatus: () => import('../auto-banking-status'),
    AutoBankingLinkButton: () => import('../auto-banking-link-button'),
  },
  props: {
    bankAccountForm: {
      type: Object,
      required: true,
      default: () => ({
        id: null,
        bayAccount: null,
      })
    }
  },
  data() {
    return {
      bayAccount: null,
    }
  },
  watch: {
    'bayAccount.isEnabled'(newValue, oldValue) {
      if(oldValue !== undefined) {
        this.toggleStatus('bay')
      }
    },
  },
  created() {
    this.bayAccount = this.bankAccountForm.bayAccount
  },
  methods: {
    ...mapActions(['toggleBankingStatus']),
    toggleStatus(banking) {
      this.toggleBankingStatus({
        id: this.bankAccountForm.id,
        banking,
      })
    }
  }
})
</script>

<style scoped>

</style>